const appUrl = "http://103.175.163.162:5080";

export const ApiConfig = {
  // =========EndPoints==========

  buyCoin: "create_payment",
  support: 'support',


  
  // ============URLs================
  baseUrl: `${appUrl}/`,
  baseAuthGetway: `${appUrl}/v1/getway/`,


};
