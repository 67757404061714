import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function alertErrorMessage(message) {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT
  });
}

function alertSuccessMessage(message) {
  toast.success(message, {
    position: toast.POSITION.TOP_LEFT
  });
}

export { alertErrorMessage, alertSuccessMessage };

