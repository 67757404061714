import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'


const injected = injectedModule()
const wcV2InitOptions = {
  projectId: '35327a613e0521e2bfa4d0df11153a64',
  requiredChains: [
    
     56,    // mainnet
    /* 97  */  // testnet
  ],
}
const walletConnect = walletConnectModule(wcV2InitOptions)

export const onboard = Onboard({
  wallets: [
    injected,
    walletConnect
  ],
  theme: 'dark',
 
  chains: [
   
   /*  {
      id: '0x61',
      token: 'BNB',
      label: 'Binance Smart Chain Testnet',
      rpcUrl: 'https://dry-withered-diagram.bsc-testnet.discover.quiknode.pro/ddb912e136c16a037998170a6970ab18e0050303/'
    }, */
    {
      id: '0x38',
      token: 'BNB',
      label: 'Binance Smart Chain',
      rpcUrl: 'https://bsc-dataseed.binance.org/'
    },
  ],

})
