import { ApiConfig } from "../apiConfig/apiConfig";
import { ApiCallPost } from "../apiConfig/apiCall";

const AuthService = {

  submitBuyDetails: async (currency, firmName, website, structure, other, city, country, firstName, lastName, tittle, email, phone, walletData,zipcode) => {
    const { baseAuthGetway, buyCoin } = ApiConfig;
    const url = baseAuthGetway + buyCoin;
    const params = {
      currency: currency,
      firm_name: firmName,
      website: website,
      organizalonal_structure: structure,
      other: other,
      city: city,
      country: country,
      first_name: firstName,
      last_name: lastName,
      title: tittle,
      email: email,
      phone: phone,
      address: walletData,
      zipcode:zipcode
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  contactuser: async (name, email, subject, message) => {
    const { baseAuthGetway, support } = ApiConfig;
    const url = baseAuthGetway + support;
    const params = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


};

export default AuthService;
