import React from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
    window.scrollTo({ top: 30, behavior: 'smooth' });

    return (
        <main>

            {/*  <!-- Hero --> */}
            <section id="her_sec" className="section main_sec z-2">
                <div className="container container-xl z-2">
                    <div className="row pt-10">
                        <div className="col-lg-6 ">
                            <h1 className="display-3 mb-3 text-gradient">eSg Coin for building B2B Crypto Derivatives and Synthetic Structured Financial Products</h1>
                            <p className="mb-3"></p>
                            <div className="d-flex align-items-center justify-content-start mt-4" >
                                {/* <Link to="" className="btn  btn-primary  btn-xl px-md-4 mr-md-3"  data-toggle="modal" data-target="#exampleModalbuy_coin" >Buy Now</Link> */}
                                <Link to="assets/esg_whitepaper.pdf" target="_blank" className="btn btn-primary btn-xl px-md-4 mr-md-3" >Whitepaper</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="assets/img/main_img.png" className="main_img" />
            </section>

            {/*  <!-- about --> */}
            <section id="about_sec" class="section about_sec">
            <div class="container container-xl">
                {/* <!-- Title --> */}
                <div class="row">
                    <div class="col text-center">
                        <div class="mb-5 page_title ">
                            <span class="h5 text-center text-gradient">About us</span>
                            <h2>About eSg Coin</h2>
                            <img src="assets/img/divider.svg" />
                            <p class="mt-3" > Our utility Token was created to disrupt the existing inefficient ESG credit rating system using our proprietary and socially impactful digital currency trading strategy </p>
                        </div>
                    </div>
                </div>
                <div class="row ">

                    <div class="col-lg-6" >
                        <div class="about_txt pr-xl-5" >
                            <h6 class="my-4  text-justify">
                                We’ve built out a reliable, cost-efficient B2B business tool for world capital market participants using digital currency technology and Polygon blockchain innovation to reduce the friction and unnecessary complexity of the archaic, existing ESG credit rating process.
                            </h6>
                            <p class="text-justify" >
                               Our eSg Coin was created as a B2B digital currency to disrupt the existing opaque, antiquated ESG credit rating system with an open source solution. It is designed for small and middle capital market issuers of public or private securities who are seeking a reliable, cost-efficient solution to improving their existing score or obtaining an initial positive ESG credit rating. Our eSg Coin allows the token user to avoid the existing non-standardized and confusing system to achieve a positive ESG credit rating providing a clear, quantitative solution that your ESG project investors will recognize and accept B2B eliminating or reducing third party fees you’re now paying to the private ESG rating companies. It is our belief system that S&P, Moodys and Fitch will become aware of the widespread capital market usage of our eSg Coin as it organically migrates across high volume securities trading platforms and major crypto exchanges worldwide. Contact our Capital Markets Trading Desk for more details.
                            </p>
                            <p class="text-justify" >
                                Most importantly, the capital markets adoption and regular use of our utility token will deliver a major social impact to economically and disadvantaged communities while concurrently providing a real and tangible, “non-green washed” solution for the business community to support, along with creating a standardized metric to define the “S” in ESG. Our unique and proprietary coin sale proceed action plan as outlined in our White Paper will provide a major social impact for many generations.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6" >
                        <img src="assets/img/about_img.svg" class="img-fluid about_img my-3 my-md-0" alt="" />
                    </div>
                    <div class="col-md-6" >
                        <ul class="about_bullets" >
                            <li class="" >
                                The total value locked (TVL) of the eSg Coin issued will be 1 billion which is a safe prospect for attracting more investors as a high amount is locked in decentralized ledgers through smart contracts. This value represents the extent of usability and liquidity and depicts a healthy platform for investment.
                            </li>
                            <li class="">
                                The maintenance of the eSg Coin value in accordance with the USD peg will prevail as long as the USD is still considered the principal world reserve currency
                            </li>
                            <li class="">
                                The founding entity of the eSg Coin is Black-founded and has been Black-owned for 41 years including the 31 years of mortgage trading experience in the US capital markets
                            </li>
                            <li  class="">
                                Unlike other organizations indulging in strategic communication and marketing to display a forged environmental and social responsibility, the eSg Coin token holders exhibit irrefutable social representation by delivering an evident and permanent social impact on the society. The service provided through token sales proceeds is poles apart from the conceptual, feel-good, greenwash strategies used by companies tampering with their ESG credit scores  </li>
                            
                            <li  class="">
                                Investors can earn high returns while simultaneously supporting the open-source solution with an innovative and highly reliable approach of the ESG credit rating system which has great potential for becoming a convention in the future unlike the current opaque status quo of the credit rating system
                            </li>
                            <li  class="">
                                The impossibility of government and other political authorities to enable confiscation of the eSg Coin or dilution of their value through inflation

                            </li>
                           
                        </ul>
                    </div>
                    <div class="col-md-6" >
                        <ul class="about_bullets" >
                            
                           {/* <!--  <li  class="">
                                The distributed ledger built on the robust Polygon blockchain leveraging its advantages of the PoS consensus mechanism, increased stakeholder participation, high security, and self-upgrading feature facilitates better economic activity with the removal of intermediaries from the network
                            </li> --> */}
                            <li class="">
                                The existence of the digital divide in various economically restrained communities can be bridged only through a high-level social commitment offered by socially responsible companies. Hence, the eSg Coin token holders obtain a qualitative measurement of their social commitment to providing broadband technology services using the token sale proceeds while also paying the Living Wage of local workers employed in such projects. 
                            </li>
                            <li class="">
                                Immune to hyperinflationary situations, economic disasters, or bank insolvency and failure.
                            </li>
                            <li class="">
                                With the forecast of 2023 for the structured finance market indicating potential growth, investment in the eSg Coin can assure beneficial results since the coin sales proceeds are converted to structured credit lending to the socially-committed digital technology service providers
                            </li>
                            <li  class="">
                                The eSg token holders are directly accountable for creating long-standing social impact in economically disadvantageous locations. Thus, their ESG credit rating is positively impacted
                            </li>
                            {/* <!-- <li  class="">
                                One of the key features of the eSg Coin trading where the coin sale proceeds are used to fund retail broadband technology services businesses will be the extensive utilization of the US Small Business Administration 7(a) lender loan guarantee program
                            </li>     --> */}
                        </ul>
                    </div>

                    
                </div>
            </div>
        </section>

            {/*  <!-- Our Features --> */}

        <section id="features_sec" class="section feature_sec  bg-gray ">
            <div class="container">
                {/* <!-- Title --> */}
                <div class="row">
                    <div class="col text-center">
                        <div class="mb-5 page_title ">
                            {/* <!-- <span class="h5 text-center text-gradient">Our eSg Coin Story was created to be</span> --> */}
                            <h2> Our eSg Coin Story was created to be helpful to You</h2>
                            <img src="assets/img/divider.svg" />
                        </div>
                    </div>
                </div>

                <div class="row no-gutters" >
                    <div class="col-md-6" >
                        <div class="cc_txt bb br pr-md-5 pb-md-5" >
                            <h4> Utility Token Overview  </h4>
                            <hr/>
                            <p class="text-justify" >
                                This coin will help to standardize the ESG
                                credit rating process for companies seeking
                                to improve their ESG rating. 

                                American Funds stated in its ESG Global Study 2022 that ESG adoption has grown from 84% to 89%for 1,100 professional investors from 19 markets around the globe. Our  B2B digital currency business tool was created to assist micro, small and medium cap companies seeking a low cost standardized solution to obtaining or improving their ESG credit rating score required for access to world capital markets.

                                The coin sale
                                proceeds will be used to build out familyfriendly, public access computer locations
                                providing low-cost, retail broadband
                                technology services in socially underserved
                                and economically disadvantaged low-income
                                communities around the US and the Western Hemisphere. 


                            </p>
                        </div>
                        <div class="cc_txt bb pr-md-5 pt-4 br pb-md-5" >
                            <h4> Salient Data  </h4>
                            <hr/>
                            <ul> 
                                <li><strong>Issuer:</strong> eSg Coin LTDA </li>
                                <li><strong>Industry:</strong> Structured credit lending </li>
                                <li><strong>Domicile:</strong> Costa Rica </li>
                                <li><strong>Token Supply:</strong> 1B </li>
                                <li><strong>Tokens for Sale:</strong> 600M / 60% </li>
                                <li><strong>Token Treasury / R&D / Reserve:</strong> 300M  / 30% </li>
                                <li><strong>Token Distributions / Allocations:</strong> 100M / 10% 
                                    <br/>
                                    <ol>
                                        <li>Marketing Promotion, Legal /
                                        Administration 3.00% </li>
                                        <li>Strategic investors / Partners /
                                        Sponsors 2.75% </li>
                                        <li>Charitable Donations /
                                        Contributions 1.00% </li>
                                        <li>Advisory Board 3.25%</li>
                                    </ol>
                                </li>                                
                                <li><strong>Token price in USD:</strong> 1 eSg Coin = $0.47 USD </li>
                                <li><strong> Accepted currency:</strong> USDC or USDT  </li>
                                <li> <strong>Ticker:</strong> eSgC </li>
                                <li><strong> Token Name:</strong> eSg Coin </li>
                                <li> <strong>Legal:</strong> Europe, Central/ South America and Southeast Asia </li>
                                <li><strong> Blockchain:</strong> Polygon </li>
                            </ul>
                        </div>

                        <div class="cc_txt bb pr-md-5 pt-4 br pb-md-5" >
                            <h4> Unique Use Cases  </h4>
                            <hr/>
                            <p class="mb-2" ><strong>Store of Value </strong></p>
                            <ul>
                                <li>Companies – utility token that provides
                                cost-efficient, quantifiable ESG credit
                                score solution that is uniform and
                                measurable by all ESG credit rating
                                agencies  </li>
                                <li>Individuals – social impact token
                                representing a commitment to
                                economically disadvantaged and socially
                                underserved communities</li>
                            </ul>

                            <p class="mb-2" ><strong>Medium of Exchange</strong></p>

                            <ul>
                                <li>Location customers – will be able to
                                exchange eSg Coin for location
                                broadband technology services in order to
                                promote native organic token usage</li>

                                <li>Location vendors – will be able to accept
                                the coin as payment for providing location
                                equipment and services</li>
                            </ul>
                        </div>

                        <div class="cc_txt pr-md-5 pt-4 br pb-md-5" >
                            <h4> Coin Sale Proceeds Action Plan  </h4>
                            <hr/>
                            <p class="text-justify mb-0" > 
                                The primary use of proceeds will be for
                                structured credit  lending to any public or private company that requires financing for providing low-cost retail broadband
                                technology services, for example, our recent
                                investment in a social impact start-up Delight
                                Technology Services (an associated or affiliated entity). 

                                Its
                                family-friendly locations will provide 5G-ready
                                high-speed public access computers for
                                hourly rental to hybrid/remote workers and
                                virtual learning students. 

                                Collaborative pods
                                for working or streaming movies, gaming
                                computers for e-sports and private video call
                                areas, plus a self-service printing kiosk. The
                                digital divide is real. 

                                Extended, long-term
                                consumer price inflation is causing working
                                class, economically disadvantaged families
                                to disconnect their unaffordable home
                                internet service. 

                                Having access to low cost,
                                high quality broadband technology services
                                will prove to be extremely valuable to families
                                as their disposable income erodes due to
                                inflation. 

                                Therefore, up to 200 locations are
                                planned over the next seven years, thereby
                                providing long term support for the “S” in
                                ESG. 
                            </p>
                            
                        </div>




                    </div>


                     <div class="col-md-6" >
                        <div class="cc_txt bb pl-md-5 pb-md-5" >
                            <h4> Coin Issuer Profile </h4>
                            <hr/>
                            <p class="text-justify" >
                               eSg Coin LTDA is an associated entity with
                                US-based World Business Credit, a 41-year
                                old organization currently operating as a
                                privately-held Commercial Mortgage Trust.
                                Its Advisory Board is a socially diverse team
                                of capital market professionals. The firm has
                                a business history of originating and trading
                                structured credit, hospitality mortgage
                                portfolios for CMBS and then retaining
                                residual pieces. Historical counterparties for
                                its various transactions have included Citi,
                                Goldman Sachs, Morgan Stanley, and
                                Bayview Asset Management. 
                            </p>
                            <p class="text-justify mb-0" >
                                Its investment strategy is now evolving from
                                financing analog industry businesses to
                                financing digital industry businesses. This
                                has transitioned into a new, dynamic, and
                                innovative concept for public access
                                computer locations being financed and built
                                out by World Business Credit called Delight
                                Technology Services. World Business Credit
                                has aligned itself with the Office Depot team
                                to strategically advise it and to provide it with
                                high-speed 5G computers and technology
                                equipment furniture for our Delight
                                Technology Services locations. Office Depot
                                has expressed that they have never seen
                                such an exciting concept. To help support the
                                design implementation and daily operations,
                                World Business Credit has hired a
                                technology services general manager from
                                the UCLA CLICC team (which provides rental
                                computers and laptops to students) in order
                                to manage and coordinate its Delight
                                investment.
                            </p>
                        </div>
                        <div class="cc_txt bb  pl-md-5 pt-md-5 pb-md-5" >
                            <h4> Coin Issuer Profile </h4>
                            <hr/>
                            <p class="text-justify" >
                               The most difficult metric to measure is the “S”
                                in ESG. The three major credit rating
                                agencies, S&P, Moody’s, and Fitch, and
                                three of the six most highly recognized
                                private ESG rating companies, RepRisk,
                                Sustainalytics ESG Risk Ratings, Corporal
                                Knights Global 100, were all contacted and
                                invited to comment on the creation of this eSg
                                Coin. All declined to comment, thereby
                                protecting their own propriety ESG definitions
                                of “S” for determining a social impact
                                commitment. We are providing an updated
                                iteration to the ESG credit rating process
                                using innovation to reduce complexity. 
                            </p>
                            <p class="text-justify mb-0" >
                               We believe our eSg Coin provides the
                            uniformity to the ESG credit rating process.
                            This coin provides a quantitative liquid crypto
                            industry standardized token that will be
                            clearly understood and recognized as a costefficient, standardized tool that disrupts the
                            current industry rating status quo. We are
                            replacing the current opaque antiquated ESG
                            credit rating system with an open source
                            solution.
                            </p>
                        </div>
                        <div class="p-md-3" >
                            <img src="assets/img/proforma.png" />
                        </div>

                    </div>
                    

                   

                </div> 




            </div>
        </section>

            {/* <!-- Road Map --> */}
            <section id="roadmap_sec" class="section roadmap_sec devprocess ">
            <div class="container">
                {/* <!-- Title --> */}
                <div class="row">
                    <div class="col text-center">
                        <div class="mb-5 page_title ">
                            <span class="h5 text-center text-gradient">Roadmap</span>
                            <h2>Implementation Sheet</h2>
                            <img src="assets/img/divider.svg" />
                        </div>
                    </div>
                </div>




                <div class="procedure-wrap">
                        <ul>
                            <li class="row">
                                <div class="col-lg-6  pd-0  d-lg-block d-md-none d-none ">
                                    <div class=" contents-l card  border-dark   p-5 p-md-4 card_ssm">
                                        <h3>Quarter 3 - 2022</h3>
                                        <p> <i class="fa fa-arrow-right mr-2" ></i> Launch of a dedicated website to enable the proliferation of eSg Coin - September 2022</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i>  Building a solid eSg crypto community with the aid of social media and marketing experts, combining third-party apps, engaging the members through polls and contests, and enabling the inflow of innovative ideas. The community performance is measured using an analytics tool to ensure its sustenance</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i>  Indulging in extensive marketing activities to spread awareness of the benefits of the eSg Coin. Employing strategies like resourceful web design, email campaigning, social media campaigning, crypto community marketing, paid promotions, and display campaigns</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Press Release to spread the word on the launch and ICO of the eSg Coin ● Conducting a pre-screening conference for cornerstone investors who are the crucial determinants of IPO value</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i>Announcement of White Paper and thus enabling the organizations in need of higher ESG credit rating to understand the value and significance of the eSg Coin investment</p>
                                    </div>
                                </div>
                                <div class="col-lg-6  col-sm-4 pd-0 ">
                                    <div class="imgs-l">
                                        <div class="process-icon  border-dark  ">2022</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 pd-0 col-sm-8  d-lg-none d-md-block d-sm-none ">
                                    <div class=" contents-l card  border-dark   p-5 p-md-4 card_ssm">
                                        <h3>Quarter 4 - 2022</h3>
                                        <p> <i class="fa fa-arrow-right mr-2" ></i> Launch of a dedicated website to enable the proliferation of eSg Coin - September 2022</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i>  Building a solid eSg crypto community with the aid of social media and marketing experts, combining third-party apps, engaging the members through polls and contests, and enabling the inflow of innovative ideas. The community performance is measured using an analytics tool to ensure its sustenance</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i>  Indulging in extensive marketing activities to spread awareness of the benefits of the eSg Coin. Employing strategies like resourceful web design, email campaigning, social media campaigning, crypto community marketing, paid promotions, and display campaigns</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Press Release to spread the word on the launch and ICO of the eSg Coin ● Conducting a pre-screening conference for cornerstone investors who are the crucial determinants of IPO value</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i>Announcement of White Paper and thus enabling the organizations in need of higher ESG credit rating to understand the value and significance of the eSg Coin investment</p>
                                    </div>
                                </div>
                            </li>
                            <li class="rl row">
                                <div class="col-lg-6 pd-0 col-sm-4 ">
                                    <div class="imgs-r">
                                        <div class="process-icon  border-dark  ">2022</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 pd-0 float-right col-sm-8  visible-xs ">
                                    <div class=" contents-r card  border-dark   p-5 p-md-4 card_ssm">
                                        <h3>Quarter 4 - 2022</h3>
                                        <p> <i class="fa fa-arrow-right mr-2" ></i> Smart Contract development aided by the Polygon blockchain to manage eSg Coin trading</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i>Presale of eSg Coins - Tranche 1: a crowdfunding measure to help with the project development</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Launching of eSg Coin: the market launch of the eSg Coin through the initial coin offering (ICO) 24</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Airdrop of eSg tokens to holders of a certain amount to promote awareness</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Involving top influencers in the project to enable effective marketing and token identity</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Sale - Tranche 2: the next level of sale to facilitate coin circulation</p>
                                    </div>
                                </div>
                            </li>
                            <li class="row">
                                <div class="col-lg-6  pd-0  d-lg-block d-md-none d-none ">
                                    <div class=" contents-l card  border-dark   p-5 p-md-4 card_ssm">
                                        <h3>Quarter 1 - 2023</h3>
                                        <p> <i class="fa fa-arrow-right mr-2" ></i>  Implementation of social media, print media, and direct marketing campaigns to gain more visibility in the crypto market regarding the social impact of eSg Coins</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Completion of multi-exchange listings and thus inducing liquidity allowing the investors to choose from various markets to buy or sell eSg Coins</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Sale: Tranche 3 </p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Major token sale appreciation follow-up campaign - February 2023</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 pd-0 col-sm-4 ">
                                    <div class="imgs-l">
                                        <div class="process-icon bg-primary border-dark  ">2023</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 pd-0 col-sm-8  d-lg-none d-md-block d-sm-none">
                                    <div class=" contents-l card  border-dark   p-5 p-md-4 card_ssm">
                                        <h3>Q1: 2022</h3>
                                        <p> <i class="fa fa-arrow-right mr-2" ></i>  Implementation of social media, print media, and direct marketing campaigns to gain more visibility in the crypto market regarding the social impact of eSg Coins</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Completion of multi-exchange listings and thus inducing liquidity allowing the investors to choose from various markets to buy or sell eSg Coins</p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Sale: Tranche 3 </p>
                                        <p><i class="fa fa-arrow-right mr-2" ></i> Major token sale appreciation follow-up campaign - February 2023</p>
                                    </div>
                                </div>
                            </li>
                            
                        </ul>
                    </div>  

            </div>
        </section>

            {/* <!-- Coin Allocation --> */}
            <section id="token_sec" className="section roadmap_sec pt-0  devprocess  section-lg ">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="mb-5 page_title ">
                                <h2 className="text-gradient" >Coin Allocation</h2>
                                <img src="assets/img/divider.svg" />
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-md-10 m-auto" >
                            <div id="Carousel2" className="carousel slide " data-ride="carousel">
                                <div className="carousel-inner rounded   border border-dark rounded px-md-5 px-3">
                                    <div className="carousel-item active">

                                        <div className="row align-items-center" >
                                            <div className="chart col-md-6"><div className="imgs"><img src="assets/img/chart/chart_main.png" alt="" /></div></div>
                                            <div className="col-md-6">
                                                <div className="label-chatrs">
                                                    <div className="lsits">
                                                        <h4 className="clabels-text mb-3">Token Supply:  <strong>1B</strong> </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row align-items-center" >
                                            <div className="chart col-md-6"><div className="imgs"><img src="assets/img/chart/1.png" alt="" /></div></div>
                                            <div className="col-md-6">
                                                <div className="label-chatrs">
                                                    <div className="lsits">
                                                        <h4 className="clabels-text mb-3">Tokens for Sale: 60% </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row align-items-center" >
                                            <div className="chart col-md-6"><div className="imgs"><img src="assets/img/chart/2.png" alt="" /></div></div>
                                            <div className="col-md-6">
                                                <div className="label-chatrs">
                                                    <div className="lsits">
                                                        <h4 className="clabels-text mb-3">Token Treasury/ R&D/ Reserve: 30% </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row align-items-center" >
                                            <div className="chart col-md-6"><div className="imgs"><img src="assets/img/chart/3.png" alt="" /></div></div>
                                            <div className="col-md-6">
                                                <div className="label-chatrs">
                                                    <div className="lsits">
                                                        <h4 className="clabels-text mb-3">Marketing Promotion, Legal/Administration: 3% </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row align-items-center" >
                                            <div className="chart col-md-6"><div className="imgs"><img src="assets/img/chart/4.png" alt="" /></div></div>
                                            <div className="col-md-6">
                                                <div className="label-chatrs">
                                                    <div className="lsits">
                                                        <h4 className="clabels-text mb-3">Strategic investors/ Partners/ Sponsors: 2.75%</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row align-items-center" >
                                            <div className="chart col-md-6"><div className="imgs"><img src="assets/img/chart/5.png" alt="" /></div></div>
                                            <div className="col-md-6">
                                                <div className="label-chatrs">
                                                    <div className="lsits">
                                                        <h4 className="clabels-text mb-3">Charitable Donations/Contributions: 1.00% </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row align-items-center" >
                                            <div className="chart col-md-6"><div className="imgs"><img src="assets/img/chart/6.png" alt="" /></div></div>
                                            <div className="col-md-6">
                                                <div className="label-chatrs">
                                                    <div className="lsits">
                                                        <h4 className="clabels-text mb-3"> Advisory Board: 3.25% </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev nuro_arrow   border border-dark bg-primary rounded" href="#Carousel2" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next nuro_arrow   border border-dark bg-primary rounded" href="#Carousel2" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5" >
                        <div className="col-md-6 col-lg-3" >
                            <Link to="assets/esg_whitepaper.pdf" target="_blank" className="btn btn-primary btn-block py-3" > Download Whitepaper</Link>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}

export default LandingPage