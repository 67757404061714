import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Web3 from 'web3';
import { onboard } from "../../../lib/WalletCoonect";

const AuthHeader = () => {

    const [isActive, setIsActive] = useState(false)
    const [isActiveToken, setIsActiveToken] = useState(false)
    const [isActiveAbout, setIsActiveAbout] = useState(false)
    const [isActiveFeatures, setIsActiveFeatures] = useState(false);
    const [walletData, setWalletData] = useState('');

    const [web3Connected, setWeb3] = useState('');

    const connectWallet = async () => {
        const wallets = await onboard.connectWallet()
        setWalletData(wallets[0]?.accounts?.[0]?.address);
    }

    const navigate = useNavigate();
    const handleRoadMap = () => {
        const roadmapSec = document.getElementById('roadmap_sec');
        if (roadmapSec) {
            roadmapSec.scrollIntoView({ behavior: 'smooth' });
            setIsActive(true);
            setIsActiveToken(false);
            setIsActiveFeatures(false);
            setIsActiveAbout(false);
        }
    };

    const handleFeatures = () => {
        const roadmapSec = document.getElementById('features_sec');
        if (roadmapSec) {
            roadmapSec.scrollIntoView({ behavior: 'smooth' });
            setIsActiveFeatures(true);
            setIsActive(false);
            setIsActiveToken(false);
            setIsActiveAbout(false);
        }
    };

    const handleAboutSec = () => {
        const roadmapSec = document.getElementById('about_sec');
        if (roadmapSec) {
            roadmapSec.scrollIntoView({ behavior: 'smooth' });
            setIsActiveAbout(true);
            setIsActiveFeatures(false);
            setIsActive(false);
            setIsActiveToken(false);
        }
    };

    const handleTokenSec = () => {
        const roadmapSec = document.getElementById('token_sec');
        if (roadmapSec) {
            roadmapSec.scrollIntoView({ behavior: 'smooth' });
            setIsActiveToken(true);
            setIsActiveAbout(false);
            setIsActiveFeatures(false);
            setIsActive(false);
        }
    };

    const handleContactus = () => {
        setIsActiveToken(false);
        setIsActiveAbout(false);
        setIsActiveFeatures(false);
        setIsActive(false);
    }

    const handleBuyCoin = (data) => {
        navigate('/buycoin', { state: data })
    }

    useEffect(() => {
        {
            if (walletData) {
              const web3Connected = new Web3(window.ethereum);
              setWeb3(web3Connected)
            }
        }
    }, [walletData]);

    return (
        <>
            <header className="header-global">
                <nav id="navbar-main" aria-label="Primary navigation" className="navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-dark navbar-transparent navbar-theme-primary">
                    <div className="container container-xl position-relative">
                        <Link className="navbar-brand" to="/">
                            <img className="d-none d-md-block" src="./assets/img/brand/logo.png" alt="Logo" />
                            <img className="d-md-none" src="./assets/img/brand/logo-icon.png" alt="Logo" />
                        </Link>
                        <div className="navbar-collapse collapse" id="navbar_global">
                            <div className="navbar-collapse-header">
                                <div className="row">
                                    <div className="col-6 collapse-brand">
                                        <Link to="/" className="navbar-brand   py-2 px-3 rounded border border-light ">
                                            <img src="./assets/img/brand/logo-icon.png" alt="eSg logo" />
                                        </Link>
                                    </div>
                                    <div className="col-6 collapse-close">
                                        <a href="#navbar_global" className="nav_close" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" title="close" aria-label="Toggle navigation">×</a>
                                    </div>
                                </div>
                            </div>
                            <ul className="navbar-nav navbar-nav-hover mr-lg-4 align-items-lg-center">
                                <li className="nav-item">
                                    <Link to="/" className={`nav-link${isActiveAbout ? " active" : ""}`} onClick={handleAboutSec} data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global"  >
                                        <span className="nav-link-inner-text">About Us</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/" className={`nav-link${isActiveFeatures ? " active" : ""}`} onClick={handleFeatures} data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global">
                                        <span className="nav-link-inner-text">Features</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/" className={`nav-link${isActive ? " active" : ""}`} onClick={handleRoadMap} data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global">
                                        <span className="nav-link-inner-text" >Roadmap</span>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/" className={`nav-link${isActiveToken ? " active" : ""}`} onClick={handleTokenSec} data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global">
                                        <span className="nav-link-inner-text">Coin Allocation</span>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="assets/esg_whitepaper.pdf" target="_blank" className="nav-link" >
                                        <span className="nav-link-inner-text">Whitepaper</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="assets/esg_litepaper.pdf" target="_blank" className="nav-link" >
                                        <span className="nav-link-inner-text">One Pager/Lite Paper </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact_us" className="nav-link" onClick={handleContactus}>
                                        <span className=""> Contact Us </span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                        <div className="d-flex align-items-center">

                           

                            <div className="dropdown " >
                                <a className="btn btn-primary px-md-4 " href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    BUY eSg Coin
                                </a>
                                <div className="dropdown-menu dropdown_menu_custom" aria-labelledby="dropdownMenuLink">
                                    <span className="dd_link bg_light_gyay1" onClick={() => handleBuyCoin({
                                        volume: '100,000',
                                        trade_price: '0.47',
                                        dueAmount: '47,000',
                                    })}>
                                        <div className="row g-4" >
                                            <div className="col-6" >
                                                <h6 >
                                                    <small>Volume</small>
                                                    100,000
                                                </h6>
                                            </div>
                                            <div className="col-6" >
                                                <h6>
                                                    <small>Trade Price</small>
                                                    $0.47
                                                </h6>
                                            </div>
                                            <div className="col-12" >
                                                <h6 className="mb-0" >
                                                    <small> Amount Due </small>
                                                    $47,000 USD
                                                </h6>
                                            </div>
                                        </div>
                                    </span>
                                    <span className="dd_link bg_light_gyay2 my-1" onClick={() => handleBuyCoin({
                                        volume: '236,407',
                                        trade_price: '0.4230',
                                        dueAmount: '100,000',
                                    })}>
                                        <div className="row g-4" >
                                            <div className="col-6" >
                                                <h6 >
                                                    <small>Volume</small>
                                                    236,407
                                                </h6>
                                            </div>
                                            <div className="col-6" >
                                                <h6>
                                                    <small>Trade Price</small>
                                                    $0.4230
                                                </h6>
                                            </div>
                                            <div className="col-12" >
                                                <h6 className="mb-0" >
                                                    <small> Amount Due </small>
                                                    $100,000 USD
                                                </h6>
                                            </div>
                                        </div>
                                    </span>
                                    <span className="dd_link bg_light_gyay3" onClick={() => handleBuyCoin({
                                        volume: '625,783',
                                        trade_price: '0.3995',
                                        dueAmount: '250,000',
                                    })}>
                                        <div className="row g-4" >
                                            <div className="col-6" >
                                                <h6 >
                                                    <small>Volume</small>
                                                    625,783
                                                </h6>
                                            </div>
                                            <div className="col-6" >
                                                <h6>
                                                    <small>Trade Price</small>
                                                    $0.3995
                                                </h6>
                                            </div>
                                            <div className="col-12" >
                                                <h6 className="mb-0" >
                                                    <small> Amount Due </small>
                                                    $250,000 USD
                                                </h6>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <button className="navbar-toggler ml-2" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                    </div>
                </nav>
            </header >
        </>
    )
}

export default AuthHeader