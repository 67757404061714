import React from "react";
import Routing from "./Services/Routing"
import Loading from './Services/Loading';
import LoaderHelper from './Services/Loading/LoaderHelper';
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <>
    <Routing />
    <Loading ref={ref => LoaderHelper.setLoader(ref)} />
    <ToastContainer />
    </>
  );
}

export default App;
