import React from "react";
import { Link } from "react-router-dom";


const AuthFooter = () => {
    return (
        <footer id="footer_sec" className="d-flex pb-5 pt-6 pt-md-7 border-top border-dark bg-primary">
            <div className="container">
                {/* <div className="subscribe_sec text-center mb-5" >
                    <h3>Stay updated with us</h3>
                    <div className="row justify-content-center" >
                        <form action="#" className="col-md-6">
                            <div className="form-row mb-3 mt-3">
                                <div className="d-flex input_in_group">
                                    <input type="email" className="form-control" placeholder="Enter Email Address" />
                                    <button type="button" className="btn btn-primary btn-sm" data-loading-text="Sending">
                                        <span>Enter</span>
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                    <p>Contact our Trading Desk today to provide financing for your eSg Coin purchase of or above $250,000 USD.
                        <br />
                        Accountancy/Digital Asset Custodian: EY.com
                        <br />
                        Cyber Security: Packetlabs.net
                    </p>
                </div> */}
                <div className="social-center d-flex justify-content-center align-items-center" >
                    <div className="nav-wrapper position-relative">
                        <ul className="nav nav-pills nav-pill-circle ">
                            <li className="nav-item">
                                <Link className="nav-link" to="https://www.reddit.com/user/eSgCoinLTDA/" target="_blank">
                                    <span className="nav-link-icon d-block"><span className="fab fa-reddit"></span></span>
                                </Link>

                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="https://www.instagram.com/esgcoinltda/" target="_blank">
                                    <span className="nav-link-icon d-block"><span className="fab fa-instagram"></span></span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="https://twitter.com/eSgCoinLTDA" target="_blank">
                                    <span className="nav-link-icon d-block"><span class="ri-twitter-x-line"></span></span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="https://www.youtube.com/channel/UChbmfsrCeKYM3O4Mc4xYGqg" target="_blank">
                                    <span className="nav-link-icon d-block"><span className="fab fa-youtube"></span></span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="my-md-5 my-3" />
                <div className="row">
                    <div className="col">
                        <div className="d-flex justify-content-center">
                            <img src="./assets/img/brand/logo.png" height="57" className="mb-3" alt="eSg Logo" />
                        </div>
                        <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                            <p className="font-weight-normal font-small mb-0">Copyright © eSg Coin <br />
                                Designed & Developed by <Link to="https://appinop.com" target="_blank" >Appinop</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default AuthFooter