import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from '../../Component/NonAuth/LandingPage';
import AuthHeader from '../../Component/CoustamComponent/AuthHeader';
import Contactus from '../../Component/NonAuth/Contactus';
import AuthFooter from '../../Component/CoustamComponent/AuthFooter';
import BuyCoin from '../../Component/NonAuth/BuyCoin';

function App() {
    return (
        <Router>
            <AuthHeader />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/contact_us" element={<Contactus />} />
                <Route path="/buycoin" element={<BuyCoin />} />
            </Routes>
            <AuthFooter />
        </Router>
    );
}

export default App;
