import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../Services/AuthAction/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../CoustamComponent/CustomMessage";

const Contactus = () => {

    const [name, setName] = useState('')
    const [subject, setSubject] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')


    const handleContactus = async (name, email, subject, message) => {
        if (!name) {
            alertErrorMessage('Please Enter Your Name')
        } else {
            LoaderHelper.loaderStatus(true);
            await AuthService.contactuser(name, email, subject, message).then(async (result) => {
                if (result.success) {
                    LoaderHelper.loaderStatus(false);
                    try {
                        alertSuccessMessage(result.message);
                        window.scrollTo({ top: 20, behavior: 'smooth' });
                        setName('')
                        setSubject('')
                        setEmail('')
                        setMessage('')
                    } catch (error) {
                        alertErrorMessage(error);
                    }
                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            });
        }
    };


    return (
        <section id="her_sec" className="section main_sec  overflow-hidden z-2">
            <div className="container container-xl z-2">
                <div className="row pt-7 pb-5">
                    <div className="col-xl-6 " >
                        <h3 >Get in touch</h3>
                        <div className="row gx-1 mt-4" >
                            <div className="col-md-6  mb-3" >
                                <div className="card h-100" >
                                    <div className="card-body" >
                                        <p className="mb-0" > <strong> <small>Address:</small> </strong> <br /> eSg Coin LTDA, San Jose, Costa Rica</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6  mb-3" >
                                <div className="card h-100" >
                                    <div className="card-body" >
                                        <p className="mb-0" >  <strong> <small>Trading Desk:</small> </strong> <br />
                                            <Link className="text-gradient" to="mailto:strategies@esgcoinltda.com" target="_blank">strategies@esgcoinltda.com</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className="card h-100" >
                                    <div className="card-body" >
                                        <p>Contact our Trading Desk today to provide financing for your eSg Coin purchase of or above $250,000 USD.
                                        </p>

                                        <p className="mb-0" >
                                            Accountancy/Digital Asset Custodian: <Link className="text-gradient" target="_blank" href="https://www.ey.com/en_in"> <b> EY.com </b> </Link>
                                        </p>
                                        <p>
                                            Cyber Security:  <Link className="text-gradient" href="https://www.packetlabs.net/" target="_blank"  > <b>  Packetlabs.net  </b> </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="col-xl-6  col-lg-8 m-auto">
                        <div className="card">
                            <div className="card-body p-md-4 " >
                                <h3 >Contact Support</h3>
                                <form className="col-12 mx-auto mt-4">
                                    <div className="form-group">
                                        <label htmlFor="nameInputIcon2">Your Name <span className="text-danger" >*</span></label>
                                        <input className="form-control" placeholder="Enter Name" type="text" aria-label="contact name input" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="emailInputIcon2">Your e-mail <span className="text-danger" >*</span></label>
                                        <input className="form-control" placeholder="example@company.com" type="email" aria-label="contact email input" value={email} onChange={(e) => setEmail(e.target.value)} />

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="emailInputIcon2">Your Subject <span className="text-danger" >*</span></label>
                                        <input className="form-control" id="subjectInputIcon2" placeholder="Enter Subject" type="text" aria-label="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea2">Message <span className="text-danger" >*</span></label>
                                        <textarea className="form-control" placeholder="Enter your message..." id="exampleFormControlTextarea2" rows="4" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                    </div>
                                </form>
                                <div className="mt-4 col-12">
                                    <button type="button" className="btn btn-primary btn-block" onClick={() => handleContactus(name, email, subject, message)}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contactus